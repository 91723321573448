import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout/Layout'
import Description from "../components/privacy-policy-page/Description";

const PrivacyPolicy = (props) => {
    return (
        <Layout path={props.location.pathname}>
            {/*<Seo />*/}
            <Description />
        </Layout>
    )
}

export default PrivacyPolicy
