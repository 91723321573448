import React from 'react'
import styled from 'styled-components'

const Description = () => {
    return (
        <StyledDescription>
            <TextWrapper>
                <H2>PRIVACY POLICY</H2>
                <Paragraphs>
                    <P>By using, or submitting any information to, our Website or any applications or tools therein located, you consent to the collection and processing of personal data as described below.</P>
                    <P>MEDAD TECHNOLOGY L.L.C,  Commercial registration number 1008068) Emaar Square - Building 6, Office 404, P.O. Box: 114683, Dubai, UAE (“Medad Technology”, “we”, “us” or “our”), respects your preferences concerning the collection and use of your personal data.</P>
                    <P>This policy describes how we collect personal data out of your use of the <LinkMail><a href="https://medadtechnology.com" target={'_blank'}><span>www.medadtechnology.com</span></a></LinkMail> website (the “Website”) and how we use such personal data (hereinafter, the “Policy”). Medad Technology is the primary controller of personal data collected through the Website.</P>
                    <P>This Policy applies only to the personal data that we collect online, from users of our Website.</P>
                    <H4>Does Medad Technology Collect Personal Data Through The Medad Technology Website?</H4>
                    <P>You can visit most areas of the Website without providing any information about you. However, our Website may include specific applications that require personal data from you.</P>
                    <P>Before using any of the aforementioned applications we may require some personal data from you. Such personal data is needed to fulfil our legitimate interests of knowing you as a user of our website or to offer you, as a potential customer, products or services that fulfil your needs. Such personal data may also be needed for the purpose of entering into, or providing services under, a contract with you. The provision of your personal data is voluntary and is therefore based on your consent. However, if you do not provide such personal data, you may not be able to receive the requested information from us.</P>
                    <P>Medad Technology also collects and processes personal data it did not collect from you. This is the case where Medad Technology receives personal data from your device as part of the electronic communication itself – the standard electronic greeting between your computer, the network, and our servers. At this time, our server will also query your computer to see if there are “cookies” previously set by the Website to facilitate log in, or other site navigation procedures.</P>
                    <H4>What Personal Data Does Medad Technology Collect Through its Website?</H4>
                    <P>For the purposes detailed herein, Medad Technology may collect and process the following categories of personal data:</P>
                    <P>Log data: This information often consists of network routing (where you came from), equipment information (browser type), date, and time.</P>
                    <P>IP Addresses: We may collect IP addresses and domain name information. An IP address is a number that is automatically assigned to a computer whenever it is connected to the Internet.</P>
                    <P>User and Third-Party Identifiable Information: We may collect personal data from users of the Website, through registration screens, online forms, from online sales inquiries, or in e-mails that users send us. We may also collect personal data relating to individuals involved in a service requested by the User of the Website. Personal data may include name, title, company, address, phone number, e-mail address, and the like.</P>
                    <P>Cookies: Cookies are unique identifiers that we share with your device to enable our system to recognize your computer and preferences on return visits to our Website. Cookies on the Website may collect the following information: a unique identifier, user preferences and profile information.</P>
                    <P>Pixel Technologies: We may also use embedded pixel technologies to help track site usage. The site usage information collected by these technologies is associated with cookies and may provide personally identifiable information about your site use if you are a registered user of the Website.</P>
                    <H4>How Do we Use Your Personal Data Collected Through the Website?</H4>
                    <P>Medad Technology may use your personal data in several ways, as follows:</P>
                    <P>To manage its contractual or pre-contractual relationship with you;</P>
                    <P>To implement transactions or communications you requested, and, more generally, to provide you with the services and information you requested from us, including to respond to inquiries and to send you your requested company news and updates.</P>
                    <P>For pursuing its legitimate interest of improving its business practices, Medad Technology may also collect and process your personal data for the following purposes:</P>
                    <P>To help create a Website that is relevant and user friendly, by evaluating Website content, navigability and composition as well as page response rates;</P>
                    <P>To protect the security and integrity of the Website;</P>
                    <P>For system administration purposes;</P>
                    <P>To let you know about material changes made to this Policy;</P>
                    <P>To protect Medad Technology’s rights and interests through legal actions;</P>
                    <P>To perform business analytics activities relying notably on advanced profiling techniques and statistics, as more specifically described below; and</P>
                    <P>To provide you with tailored notices of our products, services, service developments as well as personalized contents that may address your needs (e-mail marketing), as more specifically described below.</P>
                    <P>Lastly, Medad Technology may be required to collect and process personal data to comply with legally mandated reporting, disclosure, as well as any legal requirements.</P>
                    <H4>Do we Implement Cookies, Profiling and E-Mailing Activities?</H4>
                    <P>We may use cookies to provide you with a better experience while using our website. We will ask for your consent to our use of cookies the first time that you enter our Website.</P>
                    <P>Medad Technology may implement profiling activities, which enables us to better understand your potential needs and expectations so as to provide you with tailored information and/or services most likely to be of interest to you. Please note that such profiling activities will not make you subject to any legal consequences, as it is just a useful way for us to get to know you better and to adapt our services’ proposals accordingly. To exercise your right to object to such profiling please contact <LinkMail><a href="mailto:admin@medadtechnology.com"><span>admin@medadtechnology.com</span></a></LinkMail></P>
                    <P>Our email marketing activity is based on you opting for this option by submitting your details to receive our news. We will include an “unsubscribe” link in each electronic communication so that you can advise us whether you wish to continue receiving such communications going forward and will take due note of your choice accordingly.</P>
                    <H4>For How Long Does Medad Technology Retain My Personal Data?</H4>
                    <P>Medad Technology will retain your personal data for no longer than is necessary for the purposes specified under this Policy, except as otherwise agreed with you on a contract or as provided by applicable law.</P>
                    <H4>Does Medad Technology Share My Personal Data With Others?</H4>
                    <P>In order to pursue the purposes referred to under this Policy, Medad Technology may need to share certain personal data with subsidiaries and affiliates in different jurisdictions (which may also act as data controllers), with service providers (e.g. those needed for the operation of the Website etc.) and other third parties. We share personal data to the parties referred above only to the extent needed to run the website and conduct the activities referred to in this policy.</P>
                    <P>Medad Technology takes appropriate steps to ensure that its personnel, affiliates and third-party vendors are bound by duties of confidentiality and that the Company implements measures such as EU standard contractual clauses to ensure that any transferred personal data remains protected and secure.</P>
                    <H4>How Do I Protect My Personal Information?</H4>
                    <P>Medad uses reasonable administrative, technical, and physical measures to protect Personal Information under our control. Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us at <LinkMail><a href="mailto:admin@medadtechnology.com"><span>admin@medadtechnology.com</span></a></LinkMail></P>
                    <P>You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity.</P>
                    <H4>Does Medad Technology Disclose Information to Authorities?</H4>
                    <P>We will need to disclose personally identifiable information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</P>
                    <H4>Does Medad Technology Automatically Collect Non-Personal Information?</H4>
                    <P>We may automatically collect the following information about your use of our Website or Services through cookies, web beacons, and other technologies: your domain name; your browser type and operating system; web pages you view; links you click; your IP address; the length of time you visit our Site or use our Services; and the referring URL, or the webpage that led you to our Website, and the following: access time, browser type, device ID, domain name, IP address, page views and referring URL. We may combine this information with other information that we have collected about you, including, where applicable, your user name, name, and other personal information.</P>
                    <H4>What Are My Rights Regarding the Submitted Personal Data?</H4>
                    <P>You are entitled to:</P>
                    <P>(i) request access to your personal data processed by Medad Technology; (ii) request the rectification of your personal data; (iii) request the erasure of your personal data; (iv) request the restriction of processing or object thereto; (v) receive your personal data in a structured and standard format as part of your right to portability; and (vi) lodge a complaint with a local supervisory authority. Should you be willing to exercise the rights referred to under this section, you may write to the email address specified under the contact section below (“How Can I Contact Medad Technology?”).</P>
                    <H4>Are Children Permitted to Use Medad Technology?</H4>
                    <P>Medad Technology does not intend to collect and process personal data from children. If you believe that a child has submitted personal data through our Website, please contact us using the information below.</P>
                    <H4>What About Other Websites?</H4>
                    <P>The inclusion of any external link on our Website does not imply our endorsement of the Website, the company (including such company’s products) that such external link leads to, and we do not accept any liability connection thereto. Any access to and use of such linked websites is not governed by this Privacy Policy, but instead is governed by the privacy policies of those third party websites.</P>
                    <H4>What if Medad Technology Changes its Privacy Policy?</H4>
                    <P>This Policy reflects our current data protection policies and practices. We reserve the right to update it from time to time, in which case the new version will be posted on the Website.</P>
                    <H4>How Can I Contact Medad Technology?</H4>
                    <P>If you have questions about this Policy, please email us on <LinkMail><a href="mailto:admin@medadtechnology.com"><span>admin@medadtechnology.com</span></a></LinkMail></P>
                    <P>© Medad Holding LLC All Rights Reserved.</P>
                </Paragraphs>
            </TextWrapper>
        </StyledDescription>
    )
}

export default Description

const StyledDescription = styled.div`
  margin-top: 21rem;
  margin-bottom: 14.7rem;
  padding-left: 12rem;
  padding-right: 12rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    margin-top: 12rem;
    margin-bottom: 7rem;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Paragraphs = styled.div`
  margin-top: 8rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 4rem;
  }
`

const LinkMail = styled.span`
  font-family: Sequel45;
  line-height: 2.6rem;
  margin-top: .7rem;

  span {
    font-size: 1.6rem;
    color: #3B869D;
  }
`

const H2 = styled.h2`
`

const H4 = styled.h4`
  font-size: 2rem;
  font-family: 'Sequel75';
  margin-top: 4rem;
  margin-bottom: 2rem;
`

const P = styled.p`
  margin-top: 2rem;
  font-family: 'Sequel45';
  font-size: 1.6rem;
  &:first-child {
    margin-top: 0;
  }
`
